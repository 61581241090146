import * as React from "react"
import { Link } from "@StarberryUtils"
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
import PropertyDetailsComponents from "@Components/PropertyDetails/PropertyDetails"
import { isSold } from '@Components/common/site/utils';
import  './PropertyDetails.scss';
import SEO from "@Components/seo";

const PropertyDetails = ( props ) => {
  const [state,setState] = React.useState({
    showMenu:false
  })

  const handlerClick = () => {
    // console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }
  return(
    <div className={state.showMenu ? "wrapper header-style open-search-block" : "wrapper header-style"}>
    <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
    <SEO/>
    <PropertyDetailsComponents/> 
    <Footer/>
    <div className="fixed-btns d-flex align-items-center justify-content-between d-lg-none">
      {!isSold() && (
        <Link to="#" className="dark-bg btn btn-primary d-md-none">Book a viewing</Link>
      )}
      <Link to="#" className="dark-bg btn btn-primary d-none d-md-inline-block">Start Marketing</Link>
      <Link to="#" className="dark-bg btn btn-secondry">Call Us</Link>
    </div>
    </div>     
  
)
  }



export default PropertyDetails